import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflareSase from '../../../containers/Services/Cloudflare/SASE';

const cloudflareData = {
  title: 'Secure Access Service Edge (SASE) with Cloudflare',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-sase.png',
    ogTitle: 'Secure Access Service Edge (SASE) Services | Tech Holding',
    ogDesc: 'Streamline Network Security and Access with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/sase',
  },
  intro: {
    heading: 'Streamline Network Security and Modernize Access in a Cloud-First World',
    paragraphs: [
      'The way we work has fundamentally changed and so must the way we secure access. Traditional security architectures centered around a corporate perimeter are no longer effective in a world where users, apps, and data are everywhere.',
      'At <b>Tech Holding</b>, we partner with <b>Cloudflare</b> to deliver a modern, cloud-native <b>Secure Access Service Edge (SASE)</b> framework that combines <b>network security</b> and <b>zero trust access</b> into a unified, global service. Whether your users are remote, hybrid, or in-office, we help you <b>secure access, enforce policies, and monitor traffic without adding complexity or latency.</b>',
    ],
  },
  sections: [
    {
      columns: [
        {
          items: [
            {
              title: 'Zero Trust Network Access (ZTNA)',
              content:
                'Enable secure, <b>identity- and context-based access</b> to internal applications without relying on legacy VPNs. With <b>Cloudflare Access</b>, every request is evaluated in real-time using signals like identity, device posture, location, and more.',
            },
            {
              title: 'Cloudflare Email Security (Area 1 Security)',
              content:
                'Protect your organization from <b>phishing, business email compromise (BEC), and malware</b> with Cloudflare’s <b>preemptive email threat detection and blocking</b>, integrated with your existing email platforms like Microsoft 365 and Google Workspace.',
            },
            {
              title: 'Secure Web Gateway (SWG)',
              content:
                'Filter, inspect, and control internet-bound traffic with Cloudflare Gateway. Prevent users from accessing malicious or inappropriate content and stop data exfiltration with <b>advanced DNS, HTTP, and HTTPS filtering.</b>',
            },
          ],
        },
        {
          items: [
            {
              title: 'Firewall-as-a-Service (FWaaS)',
              content:
                'Cloudflare Magic Firewall allows you to define and enforce <b>network-level firewall rules globally</b>, eliminating the need for on-prem firewall hardware.',
            },
            {
              title: 'Cloud Access Security Broker (CASB)',
              content:
                'Discover and secure SaaS usage across your organization. Cloudflare CASB gives you <b>visibility into shadow IT</b>, detects misconfigurations, and helps ensure compliance across your cloud services.',
            },
            {
              title: 'Device Posture & Endpoint Integration',
              content:
                'Enhance Zero Trust policies with <b>device posture checks</b>, integrating with <b>MDM platforms like Kandji, Jamf, and Intune</b> to verify device health before granting access.',
            },
            {
              title: 'Cloudflare One Platform',
              content:
                'The foundation of Cloudflare’s SASE offering, Cloudflare One unifies <b>network, application, and user security</b> into one simple control plane, deployed across <b>300+ cities globally.</b>',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Performance Matters',
    columns: [
      {
        items: [
          {
            content: '<b>Every second of delay</b> can cost conversions and frustrate users.',
          },
          {
            content:
              'Global customers expect <b>consistent performance no matter where they are.</b>',
          },
        ],
      },
      {
        items: [
          {
            content:
              'Speed is a <b>ranking factor for search engines</b> and critical for SEO success.',
          },
        ],
      },
    ],
  },
  benefits: {
    heading: 'Business Benefits of SASE with Cloudflare',
    columns: [
      {
        items: [
          {
            title: 'No More VPN Headaches',
            content:
              'Eliminate legacy VPN bottlenecks and deliver <b>faster, safer access</b> to internal tools.',
          },
          {
            title: 'Protect Users Everywhere',
            content:
              'Secure access and internet usage whether users are at HQ, at home, or on the road.',
          },
          {
            title: 'Simplify Security Stack',
            content:
              'Consolidate point solutions with Cloudflare’s <b>all-in-one Zero Trust and network security platform.</b>',
          },
        ],
      },
      {
        items: [
          {
            title: 'Enhance Visibility & Control',
            content:
              'Monitor access, activity, and traffic across your apps and users in real-time.',
          },
          {
            title: 'Meet Compliance Goals',
            content:
              'Align with security frameworks like <b>NIST, SOC 2, ISO 27001, and HIPAA</b> through strong access controls and logging.',
          },
          {
            title: 'Global Scalability & Reliability',
            content:
              'Deliver consistent policy enforcement across all locations via Cloudflare’s <b>massive global network.</b>',
          },
        ],
      },
    ],
  },

  performanceServices: {
    heading: 'Our SASE Services Include:',
    columns: [
      {
        items: [
          {
            content: 'Zero Trust Architecture Design & Deployment',
          },
          {
            content: 'VPN Replacement with Cloudflare Access',
          },
          {
            content: 'Email Security Setup with Area 1',
          },
          {
            content: 'Web Gateway Policy Configuration',
          },
        ],
      },
      {
        items: [
          {
            content: 'Cloudflare Magic Firewall Rule Management',
          },
          {
            content: 'CASB Integration and SaaS Risk Assessment',
          },
          {
            content: 'Device Posture Policy Enforcement (with MDM)',
          },
          {
            content: 'Ongoing Monitoring, Alerting & Compliance Reporting',
          },
        ],
      },
    ],
  },

  callToAction: {
    heading: 'Why Tech Holding?',
    description:
      'As a certified <b>Cloudflare Professional Services Partner</b>, Tech Holding helps organizations of all sizes <b>transition to SASE architectures with speed and confidence.</b> Our hands-on experience with modern cloud environments, endpoint management, and user-centric security ensures your teams stay productive and secure everywhere.',
  },

  accelerateText: {
    heading: 'Ready to Modernize Your Network and Secure Access?',
    description:
      'Let’s talk about how we can help you deploy Cloudflare’s SASE solution tailored to your organization’s needs.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const CloudflareSasePage = ({ data }) => (
  <CloudflareSase details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

CloudflareSasePage.propTypes = propTypes;

export default CloudflareSasePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
